import currency from "currency.js"
import React from "react"
import styled from "styled-components"
import Layout from "../../layout"
import { Link } from "react-router-dom"
import { useState } from "react"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useQuery } from "../hooks/useQuery"
import {
  getCheckoutSession,
  openCheckoutSession
} from "../../../services/stripe"
import { getUSDRate } from "../../../services/fixer"
import { editUser } from "../../../services/users"
import { createTransaction } from "../../../services/transactions"
import moment from "moment"
import { getOrders } from "../../../services/orders"
import NumberFormat from "react-number-format"

export default function SummarySimulator() {
  let query = useQuery()
  const user = useSelector((state) => state.user)
  const [initialPayment, setInitialPayment] = useState(null)
  const [subscription, setSubscription] = useState(null)
  const [hasFractions, setHasFractions] = useState(false)
  const [orderUser, setOrderUser] = useState(null)
  const [USDRate, setUSDRate] = useState(0)
  const [editValue, setEditValue] = useState(false)
  const [paymentType, setPaymentType] = useState("all")

  useEffect(() => {
    const getExchangeReate = async () => {
      const exchangeRate = await getUSDRate(user.token)
      setUSDRate(exchangeRate)
      return exchangeRate
    }
    if (user.token) {
      getExchangeReate()
    }
  }, [user])

  function getClientReferenceId() {
    return (
      (window.Rewardful && window.Rewardful.referral) ||
      "checkout_" + new Date().getTime()
    )
  }

  const onHandleStripeSubscription = async (monthlyAmount) => {
    const { data } = await openCheckoutSession(
      user.token,
      initialPayment,
      monthlyAmount,
      "subscription",
      user.id
    )
    const urlStripe = data.url
    window.open(urlStripe, "_self")
  }

  const onHandleStripePayment = async (installments) => {
    const amount = installments ? amountToPay(true) : amountToPay()
    const referalId = getClientReferenceId()

    const { data } = await openCheckoutSession(
      user.token,
      amount,
      subscription,
      "payment",
      user.id,
      referalId,
      installments
    )

    try {
      // Track events Facebook
      if (hasFractions) {
        window.fbq("trackCustom", "calificacion objetivo", {
          currency: "MXN",
          value: amount
        })
      } else {
        window.fbq("trackCustom", "calificacion B", {
          currency: "MXN",
          value: amount
        })
      }

      // Track events Intercom
      window.Intercom("trackEvent", "simulacion", {
        user_id: user.id,
        value: amount
      })
      window.Intercom("update", {
        user_id: user.id,
        I_simulador_compra_inicial: amount
      })
    } catch (err) {
      console.error(err)
    }

    const urlStripe = data.url
    window.open(urlStripe, "_self")
  }

  const getSessionDetails = async (session_id, subscriptionAmount) => {
    const session = await getCheckoutSession(user.token, session_id)
    const rfc = session.custom_fields[0].text.value
    if (!user.report.userRfc) {
      await editUser(user.token, user.id, {
        rfc: rfc
      })
    }
    await createTransaction(user.token, {
      transaction_date: moment(),
      type: "COBRANZA_DEPOSITO",
      user_rfc: user.report.userRfc ? user.report.userRfc : rfc,
      user_id: user.id,
      subtotal: session.amount_total / 100,
      stripe_id: session.payment_intent
    })
    setInitialPayment(session.amount_total / 100)
    setSubscription(subscriptionAmount)
  }

  const amountToPay = (interest) => {
    let toPay = 0
    if (hasFractions) {
      if (user.report.userBalance > initialPayment) {
        toPay = 10
      } else {
        toPay =
          Number(initialPayment) - Number(Math.trunc(user.report.userBalance))
      }
    } else {
      toPay = Number(initialPayment)
    }

    if (interest) {
      return toPay * 1.16
    }

    return toPay
  }

  useEffect(() => {
    let initialPaymentAmount = Number(query.get("initial"))
    let currency = query.get("usd")
    initialPaymentAmount =
      currency === "true"
        ? initialPaymentAmount * USDRate
        : initialPaymentAmount
    let subscriptionAmount = Number(query.get("monthly"))
    setHasFractions(query.get("hasFractions"))
    let speiAmount = Number(query.get("amount"))
    let session_id = query.get("session_id")

    if (session_id) {
      getSessionDetails(session_id, subscriptionAmount)
    } else if (speiAmount) {
      setInitialPayment(speiAmount)
      setSubscription(subscriptionAmount)
    } else {
      setInitialPayment(initialPaymentAmount)
      setSubscription(subscriptionAmount)
    }
  }, [query, USDRate])

  useEffect(() => {
    const order = async () => {
      try {
        const orderList = await getOrders(user.token, user.id)
        const validOrders = orderList.filter((order) =>
          Number(order.total_tokens_count)
        )
        setOrderUser(validOrders)
      } catch (error) {
        console.error(error)
      }
    }
    order()
  }, [user])

  return (
    <Layout dashboard lightLogo>
      <Container>
        <SectionLeft>
          <h2>Resumen de tu compra</h2>

          <div className="container-values">
            {orderUser && hasFractions && (
              <>
                <div className="container-labels">
                  <h2>
                    Tus m<sup>2</sup>
                  </h2>
                  <h2>Monto</h2>
                </div>
                <div>
                  {orderUser.map((building) => (
                    <div
                      key={building.phase_name}
                      className="container-info border"
                    >
                      <p>
                        {Number(building.total_tokens_count).toFixed(0)} m
                        <sup>2</sup> {building.phase_name}
                      </p>
                      <span>
                        {currency(building.total_tokens_value, {
                          precision: 0
                        }).format()}
                      </span>
                    </div>
                  ))}
                </div>
              </>
            )}
            <div className="container-info container-total">
              <div>
                <p>Monto total de compra:</p>
                <section className="edit-value">
                  {editValue ? (
                    <NumberFormat
                      className="input"
                      thousandSeparator
                      thousandsGroupStyle="thousand"
                      decimalScale={0}
                      prefix="$"
                      value={initialPayment !== undefined ? initialPayment : 0}
                      onValueChange={(e) => setInitialPayment(e.floatValue)}
                    />
                  ) : (
                    <span>
                      {" "}
                      {currency(initialPayment, {
                        precision: 0
                      }).format()}
                    </span>
                  )}

                  {editValue && !hasFractions && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-check"
                      width="28"
                      height="28"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#fbcca1"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      onClick={() => setEditValue(false)}
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M5 12l5 5l10 -10" />
                    </svg>
                  )}
                  {!editValue && !hasFractions && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-edit"
                      width="28"
                      height="28"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#fbcca1"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      onClick={() => setEditValue(true)}
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                      <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                      <path d="M16 5l3 3" />
                    </svg>
                  )}
                </section>
              </div>
              {user.report.userBalance > 0 && hasFractions && (
                <>
                  <div>
                    <p>Anticipos disponibles:</p>
                    <span>
                      {" "}
                      -{" "}
                      {currency(user.report.userBalance, {
                        precision: 0
                      }).format()}
                    </span>
                  </div>
                  <div>
                    <p>Total a pagar:</p>
                    <span className="total">
                      {currency(amountToPay()).format()}
                    </span>
                  </div>
                </>
              )}
            </div>{" "}
          </div>
          {/* <div className="container-info border">
              <p>Compra inicial:</p>
              <span>{currency(initialPayment, { precision: 0 }).format()}</span>
            </div>
            <div className="container-info border">
              <p>Compra mensual:</p>
              <span>{currency(subscription, { precision: 0 }).format()}</span>
            </div>
            <div className="container-info">
              <p>Total a pagar hoy:</p>
              <span className="total">
                {" "}
                {currency(initialPayment + subscription, {
                  precision: 0
                }).format()}
              </span>
            </div> */}
        </SectionLeft>
        <SectionRight>
          <h2>Elige tu forma de pago</h2>
          <div>
            <p className="description">
              Revisa tu compra y elige un método de pago
            </p>
            <div className="payment-option">
              <h2 className="de-contado-title">
                <input
                  type="radio"
                  name="payment"
                  onChange={(event) => setPaymentType(event.target.value)}
                  value="one"
                />
                De contado
              </h2>
              <div className="container-icon">
                <p className="label">Monto total a pagar:</p>
              </div>
              <p className="amount">{currency(amountToPay()).format()} mxn</p>
              {initialPayment < 1000 && (
                <p className="error">El monto mínimo es de $1.000 MXN</p>
              )}
            </div>
            {paymentType === "one" && (
              <div className="button-container">
                <button
                  disabled={initialPayment < 1000}
                  onClick={() => onHandleStripePayment()}
                >
                  Pago con Tarjeta
                </button>

                {initialPayment < 1000 ? (
                  <button disabled={initialPayment < 1000}>
                    Pago por transferencia bancaria
                  </button>
                ) : (
                  <Link
                    to={
                      user.report.userRfc
                        ? `/pago-con-spei?initial=${initialPayment}&monthly=${subscription}&tokens=${hasFractions}`
                        : `/spei-rfc?initial=${initialPayment}&monthly=${subscription}&tokens=${hasFractions}`
                    }
                  >
                    <button>Pago por transferencia bancaria</button>
                  </Link>
                )}
              </div>
            )}

            <div className="pay-monthly">
              <>
                <div className="payment-option">
                  <h2>
                    {" "}
                    <input
                      type="radio"
                      name="payment"
                      onChange={(event) => setPaymentType(event.target.value)}
                      value="months"
                    />
                    Diferido a 12 meses
                  </h2>
                  <div className="container-price">
                    <p className="label">Mensualidad de:</p>
                    <p className="amount">
                      {currency((amountToPay() * 1.2) / 12).format()} mxn
                    </p>
                  </div>
                </div>
                {paymentType === "months" && (
                  <div className="buttons">
                    <button
                      disabled={initialPayment < 1000}
                      onClick={() =>
                        onHandleStripeSubscription((amountToPay() * 1.2) / 12)
                      }
                    >
                      Plan de pagos con Urvita
                    </button>
                    <button onClick={() => onHandleStripePayment()}>
                      Con tarjeta de Crédito
                    </button>
                  </div>
                )}
                <span>¿Cuál es tu diferencia?</span>
              </>
            </div>
            {initialPayment >= 999999 && (
              <p className="warning-initialPayment">
                La compra máxima por este medio es de 999,999.00, para compras
                mayores, ponte en contacto con nosotros a través del chat.
              </p>
            )}
          </div>
        </SectionRight>
      </Container>
    </Layout>
  )
}

const Container = styled.div`
  /* height:  100%; */
  h2 {
    text-align: center;
    /* font-family: 'kansas'; */
    font-weight: 400;
  }

  h3 {
    color: #fecfa4;
    font-size: 20px;
    /* font-family: 'kansas'; */
    font-weight: 400;
  }

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`
const SectionLeft = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding: 3rem 1.5rem;
  position: relative;
  background-color: #38493c;
  color: white;

  h2 {
    font-size: 2rem;
    font-family: "calibre";
    font-weight: 500;
    color: white;
  }

  .container-values {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .container-labels {
      display: flex;
      justify-content: space-between;

      h2 {
        color: #f9a14d;
        font-size: 1.5rem;
      }
    }

    .container-info {
      display: flex;
      justify-content: space-between;
      font-size: 1.8rem;
      padding: 10px 0px;
    }

    .container-total {
      flex-direction: column;
      gap: 1rem;

      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }

      div:nth-child(2) {
        padding-bottom: 10px;
        border-bottom: 2px dashed #aaa;
      }
    }

    .edit-value {
      display: flex;
      align-items: center;
      gap: 10px;

      .input {
        background: transparent;
        border: none;
        color: white;
        width: 8rem;
        font-size: 1.7rem;
        font-weight: 500;
        border-bottom: 1px solid #fbcca1;
      }

      svg {
        cursor: pointer;
      }
    }

    p {
      margin: 0;
      font-size: 1.4rem;
      font-weight: 400;
    }

    span {
      font-size: 1.7rem;
      font-weight: 500;
    }

    .border {
      border-bottom: 2px dashed #aaa;
    }

    .total {
      color: #f9a14d;
    }
  }

  @media (min-width: 1023px) {
    padding: 3rem;
    min-height: 80vh;
  }
`
const SectionRight = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 2rem;
  background-color: #fffaf5;

  .payment-option {
    h2 {
      margin-left: -3rem;
    }
  }

  h2 {
    font-size: 2rem;
    font-family: "calibre";
    font-weight: 500;
    text-align: center;
  }

  .de-contado-title {
    text-align: left;
    margin-bottom: 15px;
  }

  div {
    display: flex;
    flex-direction: column;

    .description {
      font-size: 1.3rem;
      color: #aaa;
      margin-bottom: 1rem;
    }

    p {
      margin: 0;
    }

    .label {
      font-size: 1rem;
      color: #aaa;
    }

    .amount {
      font-size: 1.9rem;
      font-weight: 500;
    }

    .error {
      color: red;
      margin-top: 10px;
    }

    .button-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;
      justify-content: center;
      gap: 1em;
    }

    .pay-monthly {
      display: flex;
      flex-direction: column;

      h2 {
        text-align: left;
        margin-top: 2rem;
      }

      .container-price {
        margin: 15px 0px;
      }

      .buttons {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 10px;
      }

      span {
        color: #aaa;
        text-decoration: underline;
        margin-top: 10px;
      }
    }

    button {
      background-color: #fecfa4;
      padding: 15px 28px;
      width: max-content;
      font-weight: bold;
      border-radius: 30px;
      font-size: 1rem;
      font-family: "calibre";
      border: none;
      color: black;
      cursor: pointer;
    }

    button:hover {
      background-color: #f9c27a;
    }

    button:disabled {
      background-color: gray;
    }

    .warning-initialPayment {
      margin-top: 1rem;
      color: red;
    }
  }

  @media (min-width: 1023px) {
    max-width: 600px;
    margin: 0 auto;

    div {
      .button-container {
        flex-direction: row;
      }
    }
  }
`
