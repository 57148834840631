export async function getUserPlan(userToken, userId) {
  const myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/payment_plans/user/${userId}`,
      {
        method: "GET",
        headers: myHeaders
      }
    )

    const data = await response.json()
    return data
  } catch (error) {
    return { data: { error: error } }
  }
}

export async function newPaymentPlan(userToken, paymentPlanData) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${userToken}`)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/payment_plans`,
      {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(paymentPlanData)
      }
    )

    const data = await response.json()
    return data
  } catch (error) {
    console.error(error)
  }
}
