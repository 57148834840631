const colors = {
  black: "#46544a",
  white: "#FFFAF7",

  backgroundWhite: "#FFFAF8",
  backgroundDark: "#546B5A",

  primaryColor: "#546B5A",
  primaryDark: "#38493C",
  primaryBlack: "#263329",
  primaryLight: "#65796A",

  secondaryColor: "#FBCFA4",
  secondaryDark: "#FAB16B",

  highlightColor: "#F9A14D",

  operationColor: "#5B8EDC",
  preSaleColor: "#6D9672",
  launchColor: "#F4B476",

  darkText: "#38493C",
  lightText: "#fecfa4",

  lightTextHighlight: "#ffb16b",

  activeColor: "#91d2ab",
  darkHighlight: "#65796A",

  detailhighlight: "#FBCFA4",
  secondaryLight: "#E9EFE8",
  secondaryMedium: "#CDDED2",

  helperGray: "#808080",
  gridGray: "rgba(0, 0, 0, 0.20)",
  disabled: "#CCCCCC"
}

const mobileBreakpoint = "600px"
const tabletBreakpoint = "992px"

// get month and year date format
const dateFormat = (period, format) => {
  const periodDate = new Date(period)
  const monthNumber = periodDate.getMonth()
  const yearNumber = periodDate.getFullYear()

  if (format === "month") {
    return months[monthNumber]
  } else {
    return months[monthNumber] + " " + yearNumber
  }
}

const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre"
]

const countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burma",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo, Democratic Republic",
  "Congo, Republic of the",
  "Costa Rica",
  "Cote d'Ivoire",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Greenland",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, North",
  "Korea, South",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Mongolia",
  "Morocco",
  "Monaco",
  "Mozambique",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Norway",
  "Oman",
  "Pakistan",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Samoa",
  "San Marino",
  " Sao Tome",
  "Saudi Arabia",
  "Senegal",
  "Serbia and Montenegro",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe"
]

// Translate transaction concept
const transactionName = (name) => {
  let translate = ""
  switch (name) {
    case "COBRANZA_DEPOSITO":
      translate = "Abono"
      break
    case "COBRANZA_DEPOSITO_RECURRENTE":
      translate = "Abono mensual"
      break
    case "CXP_REINVERTIR":
    case "CXP_REINVERTIR_DE_BALANCE":
      translate = "Ganancias"
      break
    case "CXP_RECIBIR":
    case "CXP_RECIBIR_DE_BALANCE":
      translate = "Ganancias"
      break
    case "CXP_REINVERTIR_POR_COMISION":
      translate = "Ganancia por comisión"
      break
    case "CXP_REINVERTIR_POR_PROMOCION":
      translate = "Ganancia por promoción"
      break
    case "CXC_VTA_TOKENS":
      translate = "Compra de tokens"
      break
    case "CXP_CPA_TOKENS":
      translate = "Venta de tokens"
      break
    case "PAGO_RETIRO":
      translate = "Retiro transferido a tu cuenta"
      break
    case "CXP_PROVEEDOR":
      translate = "Bono por prestación de servicios"
      break
    case "CXC_RESERVAS":
      translate = "Hospedaje"
      break
    case "CXC_AJUSTE":
      translate = "Retenciones de impuestos acreditables pendientes extranjeros"
      break
    default:
      translate = name
      break
  }
  return translate
}

export {
  colors,
  mobileBreakpoint,
  tabletBreakpoint,
  dateFormat,
  months,
  countries,
  transactionName
}
