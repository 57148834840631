import React, { useEffect, useState } from "react"
import Layout from "../../layout"
import currency from "currency.js"
import moment from "moment"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import { useQuery } from "../hooks/useQuery"
import { getCheckoutSession } from "../../../services/stripe"
// import { newSubscription } from "../../../services/subscriptions"
import { newPaymentPlan } from "../../../services/paymentPlan"
import Loader from "../../reports/loader"

function CheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-check"
      width="34"
      height="34"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="#7bc62d"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M5 12l5 5l10 -10" />
    </svg>
  )
}

export default function GetDataCheckout() {
  let query = useQuery()
  const user = useSelector((state) => state.user)
  const [subscription, setSubscription] = useState(null)
  const [initialPayment, setInitialPayment] = useState(null)
  const [noSubscription, setNoSubscription] = useState(false)
  const [showLoader, setShowLoader] = useState(true)

  const getSessionDetails = async (session_id, initialPaymentUser) => {
    const session = await getCheckoutSession(user.token, session_id)
    console.log(session)
    await newPaymentPlan(user.token, {
      user_id: user.id,
      monthly_amount: session.amount_total / 100,
      charge_date: moment().format("DD-MMMM-YYYYY"),
      status: "active",
      subscription_stripe_payment_id: session.latest_invoice,
      stripe_subscription_id: session.subscription.id,
      stripe_customer_id: session.customer,
      payment_count: 1,
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().add(1, "Y").format("YYYY-MM-DD")
    })
    // await newSubscription(user.token, {
    //   user_id: user.id,
    //   subscription_amount: session.amount_total / 100,
    //   subscription_charge_date: moment().format("DD"),
    //   subscription_stripe_payment_id:
    //     session.payment_method_configuration_details.id,
    //   subscription_active: true,
    //   subscription_stripe_customer_id: session.customer,
    //   subscription_stripe_subscription_id: session.subscription.id,
    //   subscription_start_date: moment()
    // })
    setSubscription(session.amount_total / 100)
    setInitialPayment(initialPaymentUser)
  }

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(false)
    }, 1500)
  }, [])

  useEffect(() => {
    let session_id = query.get("session_id")
    let initialPaymentUser = query.get("initial")
    if (session_id) {
      getSessionDetails(session_id, initialPaymentUser)
    } else {
      setInitialPayment(initialPaymentUser)
      setNoSubscription(query.get("noSubscription"))
    }
  }, [])

  return (
    <Layout dashboard lightLogo>
      <Container>
        {!showLoader ? (
          <>
            <img src="/logo-urvita-min-light.png" alt="logo-urvita" />
            <div className="container-congrats">
              <div>
                <h3>¡Felicidades!</h3>
                <p>
                  Haz completado el proceso. Podrás ver reflejados tus
                  movimientos y tus m<sup>2</sup> en tu tablero.
                </p>
              </div>
            </div>
            <div className="container-info-payment">
              {initialPayment !== "0" && !subscription && (
                <>
                  <div className="info-payment">
                    <div className="container-icon">
                      <p>Compra única:</p>
                      <CheckIcon />
                    </div>
                    <p className="amount">
                      {currency(initialPayment, { precision: 0 }).format()} mxn
                    </p>
                  </div>
                  <div className="separator"></div>
                </>
              )}

              {!noSubscription && (
                <div className="info-payment">
                  <div className="container-icon">
                    <p>Este es tu plan de pagos:</p>
                    <CheckIcon />
                  </div>
                  <p className="amount">
                    {currency(subscription, { precision: 0 }).format()} mxn
                  </p>
                </div>
              )}
            </div>

            <p>
              Recuerda que si pagaste por medio de transferencia bancaria,
              tardará de uno a dos días hábiles en reflejarse tu deposito en
              nuestra plataforma.
            </p>

            <Link className="container-button" to="/tablero/tokens">
              <button>Ir a tu Tablero</button>
            </Link>
          </>
        ) : (
          <Loader />
        )}
      </Container>
    </Layout>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  max-width: 600px;
  margin: 0 auto;

  h2 {
    font-size: 2rem;
    font-family: "calibre";
    font-weight: 500;
    text-align: center;
  }

  .container-info-payment {
    display: flex;
    flex-direction: column;
    color: #aaa;

    .info-payment {
      p {
        font-size: 1.4rem;
        margin: 0;
      }

      .container-icon {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .amount {
        font-size: 2rem;
        font-weight: 500;
      }
    }

    .separator {
      /* height: 1px; */
      border: 1px dashed #aaa;
      margin: 15px 0px;
    }
  }

  img {
    width: 100px;
  }

  .container-congrats {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    div {
      display: flex;
      flex-direction: column;
      gap: 10px;
      h3 {
        font-size: 2rem;
        font-family: "calibre";
      }

      p {
        font-size: 1.2rem;
        color: black;
      }
    }

    p {
      margin: 0;
      color: #aaa;
    }
  }

  .container-button {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      background-color: #435b49;
      font-family: "calibre";
      font-size: 1rem;
      border: none;
      border-radius: 20px;
      padding: 10px 2rem;
      color: white;
      font-size: 1.1rem;
      cursor: pointer;
    }

    button:hover {
      background-color: #546b5a;
    }
  }

  @media (min-width: 1024px) {
    padding-bottom: 4rem;
  }
`
