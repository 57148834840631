import React, { useState, useEffect, useCallback } from "react"
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom"
// layout
import DefaultLayout from "../layout"
import Wrapper from "../layout/wrapper"
import BreadCrumb from "../layout/shared/breadCrumb"
// services
import { getBuildings } from "../../services/buildings"
import { getLastValues } from "../../services/trackRecord"
// components
import BuildingsList from "./buildingsList"
import BuildingDetails from "./buildingDetails"

const BuildingsContainer = ({ userToken }) => {
  let { url } = useRouteMatch()
  let history = useHistory()
  const path = history.location.pathname.slice(11).toLowerCase()
  const [buildings, setBuildings] = useState([])
  const [selectedBuilding, setSelectedBuilding] = useState({})
  const [lastValues, setLastValues] = useState()

  useEffect(() => {
    const getAllBuildings = async () => {
      const list = await getBuildings(userToken)
      setBuildings(list)
    }

    const getValues = async () => {
      const values = await getLastValues(userToken, "2024-03-30") // last month
      setLastValues(values)
    }

    getAllBuildings()
    getValues()
  }, [userToken])

  const selectBuilding = useCallback(
    (name) => {
      const selected = buildings.find((building) => {
        return building.name.toLowerCase().trim() === name.toLowerCase().trim()
      })
      setSelectedBuilding(selected)
      history.push(`${url}/${selected.name.toLowerCase()}`)
    },
    [buildings, history, url]
  )

  useEffect(() => {
    if (path.length && buildings.length) {
      selectBuilding(path)
    }
  }, [buildings, history, path, selectBuilding, url])

  const getLastBuildingValue = (phase_id) => {
    return lastValues.find((value) => value.building_phase_id === phase_id)
  }

  return (
    <DefaultLayout lightLogo dashboard>
      <Wrapper>
        <BreadCrumb />
      </Wrapper>
      {buildings && lastValues && (
        <Switch>
          <Route exact path="/edificios">
            <BuildingsList
              buildings={buildings}
              selectBuilding={selectBuilding}
              lastValues={lastValues}
            />
          </Route>

          <Route path={`/edificios/${selectedBuilding.name}`}>
            <Wrapper>
              <BuildingDetails
                userToken={userToken}
                building={selectedBuilding}
                lastValues={getLastBuildingValue(selectedBuilding.phase_id)}
              />
            </Wrapper>
          </Route>
        </Switch>
      )}
    </DefaultLayout>
  )
}

export default BuildingsContainer
