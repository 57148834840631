import React, { useState, useEffect } from "react"
import styled from "styled-components"
import NumberFormat from "react-number-format"
import { useSelector } from "react-redux"
// layout
import { colors } from "../../layout/constants"
import Tooltip from "../../layout/shared/tooltip"
import DetailButton from "../../layout/shared/detailButton"
import { getUserPlan } from "../../../services/paymentPlan"

const filled = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

const ReinvestStatus = ({ balance, tokenPrice }) => {
  const userToken = useSelector((state) => state.user.token)
  const userData = useSelector((state) => state.userData)
  const [userPlan, setUserPlan] = useState()
  const tokenPercentage = () => {
    const percentage = (balance / tokenPrice) * 100
    return Math.trunc(percentage)
  }

  useEffect(() => {
    const getPlan = async () => {
      const { data: userPlan } = await getUserPlan(userToken, userData.id)

      if (userPlan.error) {
        return
      }

      setUserPlan(userPlan)
    }

    if (userData?.id) {
      getPlan()
    }
  }, [userData, userToken])

  return (
    <ReinvestStyled tokenPercentage={tokenPercentage()}>
      <div className="text title">
        {userPlan && "Así has avanzado con tu plan "}
        <NumberFormat
          value={Math.trunc(balance)}
          prefix="$ "
          displayType={"text"}
          thousandSeparator={true}
        />{" "}
        <Tooltip
          dark
          content={"Es la suma de tus abonos y ganancias reinvertidas."}
        />
      </div>

      <p>{!userPlan && "Disponibles como anticipo para comprar m2"}</p>

      {userPlan && (
        <div className="plan-bar">
          {filled.map((month) => (
            <span
              key={month}
              className={`bar-step ${
                month <= userPlan.payment_count ? "active" : ""
              }`}
            ></span>
          ))}
        </div>
      )}
    </ReinvestStyled>
  )
}

const ReinvestStyled = styled.article`
  .plan-bar {
    height: 8px;
    margin: 1em 0;
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 5px;
  }

  .bar {
    background-color: ${colors.secondaryColor};
    height: 8px;
    margin: 1em 0;
    position: relative;
    width: 100%;
  }

  .filled-bar {
    background-color: ${colors.secondaryColor};
    bottom: 0;
    left: 0;
    position: absolute;
    top: 0;
    width: ${(props) =>
      props.tokenPercentage >= "100" ? "100%" : props.tokenPercentage + "%"};
  }

  .bar-step {
    border: 1px solid black;
    width: 100%;
    background-color: ${colors.gridGray};

    &.active {
      background-color: ${colors.secondaryColor};
    }
  }

  .text {
    font-size: 0.9rem;
    margin: 0.5rem 0;
  }

  .title {
    font-size: 1.2rem;
  }

  .helper-number {
    color: ${colors.helperGray};
  }

  @media (max-width: 968px) {
  }
`

export default ReinvestStatus
